$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    timeout:5000
});

/*Dynamic Load*/
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
};

/*
 * Animated CSS
 * $('#yourElement').animateCss('bounce');
 *  or;
 *  $('#yourElement').animateCss('bounce', function() {
 *    // Do somthing after animation
 *  });
 * */
$.fn.extend({
    animateCss: function (animationName, callback) {
        var animationEnd = (function (el) {
            var animations = {
                animation: 'animationend',
                OAnimation: 'oAnimationEnd',
                MozAnimation: 'mozAnimationEnd',
                WebkitAnimation: 'webkitAnimationEnd'
            };
            for (var t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        })(document.createElement('div'));
        this.addClass('animated ' + animationName).one(animationEnd, function () {
            $(this).removeClass('animated ' + animationName);
            if (typeof callback === 'function') {
                callback();
            }
        });
        return this;
    }
});

/*
* forbiddenWordCheck
* Author: Mike Geilenkirchen
* */
$.fn.extend({
    forbiddenWordCheck: function(forbiddenWords,selectedLanguage,callback){
        let badWords    =  forbiddenWords[selectedLanguage];
        let input       = this;
        let wordCheck   = function(){
            let text        = input.val().toLocaleLowerCase();
            let found       = false;
            for(search in badWords){
                let regex = new RegExp(search,"g");
                if(text.match(regex)){
                    found = true;
                }
            }
            if(found === true){
                input.addClass('text-danger is-invalid badword');
            }else{
                input.removeClass('text-danger is-invalid badword');
            }
            if (typeof callback === 'function') {
                callback(input,found);
            }
        };
        input.on('change blur keydown keyup',function(e){
            wordCheck();
        });
        wordCheck();
        return this
    }
});

window.isInViewportFunc = function() {
    let allowedHandler = [
        'getChildPosts',
        'getPostMedia'
    ];
    $('.vp-event').each(function () {
        if (!$(this).hasClass('.vp-started') && !$(this).hasClass('.vp-finished')) {
            if ($(this).isInViewport()) {
                $(this).removeClass('vp-event').addClass('vp-started');
                let handler = JSON.parse($(this).data('handler').replace(/'/g, "\""));
                let params = {};
                if (handler.params) {
                    params = handler.params;
                }
                if (handler.handler && typeof eval(handler.handler) === 'function' && allowedHandler.indexOf(handler.handler) !== -1) {
                    eval(handler.handler + '(params,$(this))');
                }
            }
        }
    });
}

//Manual Init
$('.vp-event').on('hover click', function () {
    isInViewportFunc();
});

//getChildPosts
function getChildPosts(params,obj){
    setTimeout(function () {
        $.post(absolute+'post/getChildPosts', {
            post_id: params.postId
        }, function (res) {
            if(res.success === true && res.html !== '' ){
                obj.children('.loader').animateCss('fadeOutUp',function(){
                    obj.html(res.html);
                    obj.removeClass('vp-started').addClass('vp-finished').removeAttr('data-handler');
                    fullpageRebuild();
                });
            }else{
                getChildPosts(params,obj);
            }
        });
    }, 100);
}

//getPostMedia
function getPostMedia(params,obj){
    setTimeout(function () {
        $.post(absolute+'post/getPostMedia', {
            post_id: params.postId
        }, function (res) {
            if(res.success === true && res.html !== '' ){
                obj.children('.loader').animateCss('fadeOutUp',function(){
                    obj.html(res.html);
                    obj.removeClass('vp-started').addClass('vp-finished').removeAttr('data-handler');
                    fullpageRebuild();
                });
            }else{
                getPostMedia(params,obj);
            }
        });
    }, 100);
}

//Fullpage Rebuild
window.fullpageRebuild = function(){
    if(document.getElementById('fullpage') !== null){
        $.fn.fullpage.reBuild();
    }
}

document.addEventListener('livewire:load', function () {
    Livewire.hook('component.initialized', (component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('element.initialized', (el, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('element.updating', (fromEl, toEl, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('element.updated', (el, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('element.removed', (el, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('message.sent', (message, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('message.failed', (message, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('message.received', (message, component) => {
        window.fullpageRebuild();
    })
    Livewire.hook('message.processed', (message, component) => {
        window.fullpageRebuild();
    })
})

//Resize Scroll
$(window).on('resize scroll', function () {
    isInViewportFunc();
});

//Ajax Complete
var ajaxCompleteTimer;
$(document).ajaxComplete(function () {
    if (ajaxCompleteTimer) {
        window.clearTimeout(ajaxCompleteTimer);
    }
    ajaxCompleteTimer = window.setTimeout(function () {
        isInViewportFunc();
    }, 100);
});

//Init Calls
$(function(){
    isInViewportFunc();
});

window.makeSquare = function(selector){
    $(selector).each(function(){
        $(this).height($(this).width());
    });
}

//Device Size
window.deviceSize = null;
window.getDeviceSize = function() {
    let width = $(window).width();
    let size = 'xs';
    if (width <= 576) {
        window.deviceSize = 'xs';
    } else if (width > 576 && width < 768) {
        window.deviceSize = 'sm';
    } else if (width >= 768 && width < 992) {
        window.deviceSize = 'md';
    } else if (width >= 992 && width < 1200) {
        window.deviceSize = 'lg';
    } else if (width >= 1200) {
        window.deviceSize = 'xl';
    }
}

window.getRandom = function(min,max){
    return Math.random() * (max - min) + min;
}

/*Loader*/
window.loader = '<i class="fad fa-spinner-third fa-spin"></i>';

//Resize Functions
window.addEventListener('resize',function(){
    getDeviceSize();
});

//On Load
window.addEventListener('load',function(){
});

//On Jquery Ready
$(document).ready(function(){

});

//Init Calls
getDeviceSize();

/*Fetch Alternative*/
// fetch('./post/getChildPosts',{
//     method:'POST',
//     headers:{
//         "Content-Type": "application/json",
//         "Accept": "application/json, text-plain, */*",
//         "X-Requested-With": "XMLHttpRequest",
//         "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr('content')
//     },
//     body:JSON.stringify({post_id:params.postId})
// })
// .then(res => res.json())
// .then((res)=>{
//     let target = $('#'+obj.attr('id'));
//     target.children('.loader').animateCss('fadeOutDown');
//     target.html(res.html);
//     target.removeClass('vp-started').addClass('vp-finished').removeAttr('data-handler');
// });
